import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Select from 'react-select';

import { appRoutes } from '@core/navigation';
import { useActions, useAppDispatch, useAppSelector } from '@core/store/hooks';
import { selectCurrentUser } from '@entities/.app/account/account.selectors';
import useAxiosPrivate from '@entities/.app/account/hooks/account.useAxiosPrivate';
import { rejectDeal as deleteDeal, getDeals, updateDeal } from '@entities/office/deal/deal.api';
import DealCard from '@features/office/deal/Deal.Card';
import usePagination from '@shared/hooks/usePagination';
import { getURL } from '@shared/lib/getURL';
import Loader from '@shared/ui/Loader';
import Pagination from '@shared/ui/Pagination';
import { useWidth } from '@shared/hooks/useWidth';
import { getFeedIdFromDealId } from '@entities/.app/appEvents/appEvents.api';
import { setAlert } from '@entities/.app/alert/alert.actions';

const initialPageLimit = 6;

const dealTypes = [
    { label: 'Грузы', value: 'cargo' },
    { label: 'Маршруты', value: 'route' },
];

const dealStatusesMock = [
    { value: 'offer', label: 'Предложения' },
    { value: 'open', label: 'На согласовании' },
    { value: 'active', label: 'В работе' },
    { value: 'done', label: 'Ожидающие закрытия' },
    { value: 'archive', label: 'Архив' },
];

const nullableDeals = [
    'Откликов по объявлениям нет',
    'Сделок на согласовании нет',
    'Текущих сделок нет',
    'Выполненных сделок нет',
    'Сделок в архиве нет',
];

const selectedDataMock = {
    counts: [...Array(5)].map((e) => 0),
    isLoaded: true,
    items: [...Array(6)].map((_, idx) => ({
        id: idx + 1,
        status: idx % 5,
        user: {
            id: Math.floor(Math.random() * 2) + 1,
            subject: 'subjest' + idx,
            fullName: 'fullName' + idx,
            companyName: 'companyName' + idx,
            avatar: `https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/${
                Math.floor(Math.random() * 1000) + 1
            }.jpg`,
        },
        route: {},
    })),
    meta: {},
};

const getIDXFromFieldValue = (value, arrObjs) => {
    for (const eIDX in arrObjs) {
        for (const k of Object.keys(arrObjs[eIDX])) {
            if (arrObjs[eIDX][k] === value) return eIDX;
        }
    }
};

export default function OfficeDealsPage() {
    //MOBILE VIEW
    const isMobile = useWidth(1200);

    //ROUTING
    const [search, setSearch] = useSearchParams();
    const navigate = useNavigate();

    //CURRENT_USER
    const currentUser = useAppSelector(selectCurrentUser);

    //TABS-SELECT
    const [dealStatuses, setDealStatuses] = useState(dealStatusesMock);
    const [tabSelectedStatus, setSelectedStatusTab] = useState(search.get('status') || dealStatuses[2].value);
    // const [selectedMemberRoleIDX, setSelectedMemberRoleIDX] = useState(0);
    const [subTabSelectedType, setSubTabSelectedType] = useState(dealTypes[0].value);
    useEffect(() => {
        setSubTabSelectedType((currentUser?.roleId === 3 && dealTypes[1].value) || dealTypes[0].value);
    }, [currentUser?.roleId]);

    //PAGINATION
    const [dealPagTotalAmount, setDealPagTotalAmount] = useState(0);
    // const [paginationItemsAmount, setPaginationItemsAmount] = useState(0);
    const DealsPag = usePagination(initialPageLimit);
    useEffect(() => {
        DealsPag.setCurrentPage(1);
        DealsPag.setStartingPage(1);
        setSearch({ status: tabSelectedStatus });
    }, [subTabSelectedType, tabSelectedStatus]);

    //DEALS

    const [selectedDeals, setSelectedDeals] = useState<any>(selectedDataMock);

    const axiosPrivate = useAxiosPrivate();
    const loadSelectedDeals = useCallback(async () => {
        setSelectedDeals((prev) => ({
            ...prev,
            isLoaded: false,
        }));

        const res = await getDeals(
            axiosPrivate,
            currentUser?.id,
            DealsPag.currentPage,
            DealsPag.pageLimit,
            getIDXFromFieldValue(tabSelectedStatus, dealStatuses),
        );

        setDealPagTotalAmount(res?.body?.items?.meta?.total);

        setSelectedDeals((prev) => ({
            ...prev,
            isLoaded: true,
            counts: res?.body?.counts || [...Array(5)].map((e) => 0),
            meta: res?.body?.items?.meta,
            items: res?.body?.items?.data,
        }));

        setDealStatuses((prev) =>
            prev.map((s, sIDX) => ({
                ...s,
                label:
                    dealStatusesMock[sIDX].label + (res?.body?.counts?.[sIDX] ? ` (${res?.body?.counts?.[sIDX]})` : ''),
            })),
        );
    }, [DealsPag, tabSelectedStatus]);
    useEffect(() => {
        loadSelectedDeals();
    }, [
        DealsPag.pageLimit,
        DealsPag.currentPage,
        // selectedMemberRoleIDX,
        currentUser.roleId,
        subTabSelectedType,
        tabSelectedStatus,
    ]);

    const { setCurrentDeal, setCurrentDealFeedId } = useActions();
    const dispatch = useAppDispatch();

    return (
        <div className="box px-0 p-sm-4 p-xl-5 shadow">
            <Link to={getURL(appRoutes.OFFICE)} className="fs-12 fw-5 d-block d-lg-none mb-3 mb-sm-5">
                <span className="green fs-15 me-2">⟵</span> Назад
            </Link>
            <h1 className="dark-blue text-center d-lg-none">Мои сделки</h1>
            {/* MENU */}
            <div
                className="d-flex justify-content-between fs-12 fw-5 title-font mb-4 mb-xl-5"
                style={isMobile ? { flexDirection: 'row' } : { flexDirection: 'column' }}
            >
                <div className="d-none d-xl-flex" style={{ justifyContent: 'space-evenly' }}>
                    {dealStatuses.map((_, idx) => (
                        <button
                            key={idx}
                            type="button"
                            className={
                                tabSelectedStatus === dealStatuses[idx].value
                                    ? 'active tab-btn ms-3 ms-sm-4'
                                    : 'tab-btn ms-3 ms-sm-4'
                            }
                            onClick={() => {
                                setSelectedStatusTab(dealStatuses[idx].value);
                            }}
                            style={{ width: '20%' }}
                        >
                            {dealStatuses[idx].label}
                        </button>
                    ))}
                </div>
                <div
                    className="d-xl-none"
                    style={{
                        width: '100%',
                        marginTop: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Select
                        isSearchable={false}
                        classNamePrefix="react-select"
                        options={dealStatuses}
                        defaultValue={dealStatuses[getIDXFromFieldValue(tabSelectedStatus, dealStatuses)]}
                        onChange={(val) => {
                            setSelectedStatusTab(val.value);
                        }}
                    />
                </div>
            </div>
            {/* TILES */}
            <div className="row row-cols-sm-2 row-cols-xxl-3 g-3 g-md-4">
                {selectedDeals?.isLoaded ? (
                    selectedDeals?.items?.length ? (
                        selectedDeals?.items?.map((item) => (
                            <div key={item.id}>
                                <DealCard
                                    currentUser={currentUser}
                                    item={item}
                                    onToMsgr={() =>
                                        navigate(getURL(appRoutes.OFFICE_MESSAGER), {
                                            state: { dealId: item?.id },
                                        })
                                    }
                                    onToDocs={async () => {
                                        setCurrentDeal(item);
                                        const feedId = await getFeedIdFromDealId(axiosPrivate, item.id);
                                        setCurrentDealFeedId(feedId);
                                        navigate(appRoutes.ROOT_OFFICE_DEAL_DOCS);
                                    }}
                                    onNext={async () => {
                                        const result = item?.lastToNextUserId
                                            ? await updateDeal(
                                                  axiosPrivate,
                                                  {
                                                      status: item?.status + 1,
                                                      lastToNextUserId: 0,
                                                      userId: currentUser?.id,
                                                  },
                                                  item?.id,
                                              )
                                            : await updateDeal(
                                                  axiosPrivate,
                                                  { lastToNextUserId: currentUser?.id, status: item?.status },
                                                  item?.id,
                                              );
                                        console.log({ result });
                                        return result
                                            ? await loadSelectedDeals()
                                            : dispatch(
                                                  setAlert('danger', 'Добавьте и согласуйте хотя бы один документ'),
                                              );
                                    }}
                                    onCancel={async () => {
                                        if ([0, 1].includes(item?.status)) {
                                            await deleteDeal(axiosPrivate, item.id);
                                        } else {
                                            item?.lastToCancelUserId
                                                ? await deleteDeal(axiosPrivate, item.id)
                                                : await updateDeal(
                                                      axiosPrivate,
                                                      { lastToCancelUserId: currentUser?.id },
                                                      item?.id,
                                                  );
                                        }
                                        await loadSelectedDeals();
                                    }}
                                />
                            </div>
                        ))
                    ) : (
                        <h6 className="text-center w-100 p-5">
                            {nullableDeals[getIDXFromFieldValue(tabSelectedStatus, dealStatuses)]}
                        </h6>
                    )
                ) : (
                    <div className="w-100 d-flex justify-content-center">
                        <Loader color="#545454" />
                    </div>
                )}
            </div>
            <div className="mt-4">
                <Pagination
                    pageLimit={DealsPag.pageLimit}
                    currentPage={DealsPag.currentPage}
                    setCurrentPage={DealsPag.setCurrentPage}
                    pagesDisplayedLimit={3}
                    itemsAmount={dealPagTotalAmount}
                    startingPage={DealsPag.startingPage}
                    setStartingPage={DealsPag.setStartingPage}
                />
            </div>
        </div>
    );
}

import { createSlice } from '@reduxjs/toolkit';
import { IDeal } from '@entities/office/deal/deal.types';
import Logger from '@shared/lib/logger';

interface IDealState {
    data: IDeal;
}
const initialState: IDealState = {
    data: null,
};

const dealSlice = createSlice({
    name: 'deal',
    initialState,
    reducers: {
        setCurrentDeal: (state, action) => {
            Logger.log('setCurrentDeal');
            state.data = action.payload;
        },
        setCurrentDealFeedId: (state, action) => {
            Logger.log('setCurrentDealFeedId');
            state.data.feedId = action.payload;
        },
    },
});
export const dealActions = dealSlice.actions;
export const dealReducer = dealSlice.reducer;
